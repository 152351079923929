<template>
  <modal @update:show="onClose" :show="show">
    <h6
      slot="header"
      class="modal-title"
    >{{ address.id ? this.$t('message.editLocation') : this.$t('message.newLocation') }}</h6>

    <div v-loading="isLoading">
      <div class="row">
        <div class="col-md-6">
          <base-input
            :label="$t('message.namePlus')"
            :placeholder="$t('message.name')"
            v-model="$v.address.address.name.$model"
            :valid="!$v.address.address.name.$invalid"
            ref="name"
          ></base-input>
        </div>
        <div class="col-md-6">
          <base-input
            :label="$t('message.customer')"
            :placeholder="$t('message.customer')"
            v-model="$v.address.customer_no.$model"
            disabled
          ></base-input>
        </div>
      </div>
      <div class="row">
        <div class="col-md-6">
          <base-input
            :label="$t('message.StreetPlus')"
            :placeholder="$t('message.Street')"
            v-model="$v.address.address.street.$model"
            :valid="!$v.address.address.street.$invalid"
          ></base-input>
        </div>
        <div class="col-md-6">
          <base-input
            :label="$t('message.NumberPlus')"
            :placeholder="$t('message.Number')"
            v-model="$v.address.address.no.$model"
            :valid="!$v.address.address.no.$invalid"
          ></base-input>
        </div>
      </div>

      <div class="row">
        <div class="col-md-6">
          <base-input
            :label="$t('message.ZIPPlus')"
            :placeholder="$t('message.ZIP')"
            v-model="$v.address.address.zip.$model"
            :valid="!$v.address.address.zip.$invalid"
          ></base-input>
        </div>
        <div class="col-md-6">
          <base-input
            :label="$t('message.PlacePlus')"
            :placeholder="$t('message.Place')"
            v-model="$v.address.address.city.$model"
            :valid="!$v.address.address.city.$invalid"
          ></base-input>
        </div>
      </div>

      <div class="row">
        <div class="col-md-4">
          <base-input :label="$t('message.DateFromPlus')">
            <flat-picker
                    slot-scope="{focus, blur}"
                    v-model="$v.address.date_from.$model"
                    :placeholder="$t('message.ChooseDate')"
                    @on-open="focus"
                    @on-close="blur"
                    @input="onFromDateChange"
                    :config="dateTimePicker"
                    class="form-control datepicker"
            />
          </base-input>
        </div>
        <div class="col-md-4">
          <base-input :label="$t('message.DateUntilPlus')">
            <flat-picker
                    slot-scope="{focus, blur}"
                    v-model="$v.address.date_to.$model"
                    :placeholder="$t('message.ChooseDate')"
                    @on-open="focus"
                    @on-close="blur"
                    :config="dateTimePicker"
                    class="form-control datepicker"
            />
          </base-input>
        </div>
        <div class="col-md-4">
          <base-input :label="$t('message.DeadlinePrint')">
            <flat-picker
                    slot-scope="{focus, blur}"
                    v-model="$v.address.deadline_print.$model"
                    :placeholder="$t('message.ChooseDate')"
                    @on-open="focus"
                    @on-close="blur"
                    :config="dateTimePicker"
                    class="form-control datepicker"
            />
          </base-input>
        </div>
      </div>
    </div>
    <template slot="footer">
      <base-button type="link" class="ml-auto" @click="onClose()">{{$t('message.cancel')}}</base-button>
      <base-button type="primary" @click="onSave" :disabled="$v.$invalid">{{$t('message.save')}}</base-button>
    </template>
  </modal>
</template>

<script>
import "flatpickr/dist/flatpickr.css";

import FlatPicker from "vue-flatpickr-component";
import { mapGetters } from "vuex";
import { required, minLength, between } from "vuelidate/lib/validators";

export default {
  name: "Einsatzort",
  components: {
    FlatPicker
  },
  props: {
    address: {
      type: Object
    },
    project: {
      type: Object
    },
    show: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      dateTimePicker: {
        enableTime: true,
        time_24hr: true,
        dateFormat: "Y-m-d H:i:00",
        altInput: true,
        altFormat: "d.m.Y H:i"
      },
      isLoading: false
    };
  },
  validations: {
    address: {
      address: {
        name: {
          required,
          minLength: minLength(4)
        },
        zip: {
          required,
          minLength: minLength(4)
        },
        street: {
          required
        },
        no: {
          required
        },
        city: {
          required
        },
      },
      deadline_print: {
      },
      date_from: {
      },
      date_to: {
      },
      customer_no: {
      }
    },
  },
  watch: {
    address: {
      immediate: true,
      handler(value) {
        const milestone = value.milestones
          ? value.milestones.find(milestone => milestone.type === "START")
          : null;

        this.dateFrom = milestone ? milestone.due : null;
      }
    },
    show: {
      immediate: true,
      handler(value) {
        if (value)
          this.focusInput();
      }
    }
  },
  methods: {
    focusInput() {
      this.$nextTick(() => {
        this.$refs.name.$el.querySelector('input').focus();
      })
    },
    onFromDateChange (e) {
     this.address.date_to = this.address.date_from
    },
    createAddress: async function(data) {
      const { $store } = this;
      const { id: project_id } = this.project;
      const { id: address_id } = await $store.dispatch("createAddress", {
        data: data.address
      });

      const { id: p_address_id } = await $store.dispatch(
        "createProject_address",
        {
          data: {
            address_id,
            project_id,
            date_from: this.address.date_from,
            date_to: this.address.date_to,
            deadline_print: this.address.deadline_print,
            customer_no: this.address.customer_no,
          }
        }
      );
    },
    onSave: async function() {
      const { $store, address, project } = this;
      const { milestones, p_address_id, ...data } = address;
      this.isLoading = true;

      if (address.address.id) {
        await $store.dispatch("updateAddress", {
          id: address.address.id,
          data: address.address
        });
        await $store.dispatch("updateProject_address", {
          id: address.id,
          data: {
            date_from: this.address.date_from,
            date_to: this.address.date_to,
            deadline_print: this.address.deadline_print,
            customer_no: this.address.customer_no,
          }
        });
      } else {
        await this.createAddress(data);
      }

      this.isLoading = false;
      this.$emit("changed");
      this.onClose();
    },
    onClose(state) {
      this.$emit("update:show", state);
    }
  },
  mounted() {
    this.focusInput();
  }
};
</script>
